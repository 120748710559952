<template>
    <div style="width: 100%;height: 100%">
        <div style="width: 100%;border-top: 1px solid #BDBFC1;margin-top: 40px;"></div>
        <div style="margin-top: 10px;">
            <div style="background: #031F88;width: 6px;height: 18px;float: left;"></div>
            <div style="font-size: 18px;height: 20px;float: left;margin-left: 5px;">{{commentData.length}}条评论</div>
        </div>
        <!-- 占位  -->
        <div style="clear: both;padding-top:24px;width: 100%;height: 100%">
            <div style="margin-top: 10px;height: 30%;">
                <div style="width: 100%;background: #FFFFFF;height: 196px;">
                    <el-input type="textarea" :rows="8" resize="none" placeholder="发布你的评论" v-model="sendContent" maxlength="500"  show-word-limit />
                </div>
            </div>
            <div style="display: flex; justify-content: end; margin-top: 5px">
                <el-button type="primary" @click="addCommand">发表评论</el-button>
            </div>
            <!--  一级评论   -->
            <div v-for="(item, index) in commentData" :key="index">
                <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                    <div style="display: flex;margin-left: 15px">
                        <div style="width: 52px;height: 52px;margin-right: 12px;margin-top: 4px">
                            <img :src="item.user.headIco" style="width: 52px; height: 52px; background-color: #6d737b; border-radius: 50%;">
                        </div>
                        <div style=" margin-top: 2px; margin-right: 5px;">
                            <div style="font-weight: 600; color: #2970FF;float: left">{{item.user.nickName}}</div>
                            <div style="float: left;margin-left: 10px;">
                                <div v-if="item.user.v == 1" style="width: 20px;height: 20px;float: left">
                                    <img  src="../../../assets/imgs/index/vipBig.png" style="width: 20px; height: 20px;"/>
                                </div>
                                <!-- 专业  -->
                                <div v-if="item.user.authenticationStatus >= 2" style="width: 49px; height: 23px;float: left;margin-left:5px;">
                                    <img  src="../../../assets/imgs/index/major2.png" style="width: 49px; height: 21px;"/>
                                </div>
                                <!-- 专家 -->
                                <div v-if="item.user.authenticationStatus >= 3" style="width:49px; height: 23px;float: left;margin-left:5px;">
                                    <img  src="../../../assets/imgs/index/expertspn2.png" style="width:49px; height: 21px;"/>
                                </div>
                            </div>
                            <div style="clear: both">
                                <span  v-if="item.user.address.cityName" style="font-size: 12.5px;color: #999999">{{item.user.address.cityName[1]}}</span>
                                <el-divider direction="vertical"></el-divider>
                                <span style="font-size: 12.5px;color: #999999">{{item.user.major}}</span>
                            </div>
                            <div style="word-break:break-all;word-wrap:break-word;margin-top: 10px;">{{item.content}}</div>
                            <div style="color: #999999; font-size: 8px; margin-top: 8px">{{item.creatTime}}</div>
                        </div>
                    </div>
                    <div style="float: right;margin-top: 75px;">
                        <div v-if="!showReplyArray[index]" style="color: #999999;font-size: 10px; line-height: 2px;margin-top: 15px;margin-right:15px;float: left;cursor: pointer" @click="showEditor(item,index)">回复</div>
                        <div v-else style="color: #999999;font-size: 10px; line-height: 2px;margin-top: 15px;margin-right:15px;float: left;cursor: pointer" @click="cancelReply(index)">取消回复</div>
                        <div style="float: left;margin-top: 5px">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon2.png" style="width: 16px;height: 17px;float: left">
                            <div style="height: auto;font-size: 11px;float: left;margin: 2px;color: #999999">0</div>
                        </div>
                        <div v-if="item.user.uuid === user.uuid" style="color: #999999;font-size: 10px; line-height: 2px;margin-top: 15px;float: left;margin-left: 20px;cursor: pointer" @click="delComment(item.id)">删除</div>
                    </div>
                </div>
                <!--:visible.sync="showReply" :title="" -->
                <label v-if="showReplyArray[index]">
                    <div  style="display: flex;margin-left: 70px;">
                        <div style="width: 36px;height: 36px;margin: 20px 15px 0 0">
                            <img :src="user.headIco" style="width: 36px;height: 36px;border-radius: 50%">
                        </div>
                        <div style="background: #FFFFFF;height: 88px;width: 100%;margin-top: 20px;">
                            <el-input type="textarea" :rows="3" :placeholder="replyInfo.title" v-model="replyContent" maxlength="200"  show-word-limit />
                        </div>
                    </div>
                    <div style="display: flex; justify-content: end;margin-bottom: 10px;">
                        <el-button type="primary" @click="addReply">发表回复</el-button>
                    </div>
                </label>
                <!-- 二级评论   -->
                <div style="display: flex; justify-content: space-between; margin-bottom: 12px; margin-left: 70px" v-for="(reply, num) in item.sub" :key="num">
                    <div style="display: flex;">
                        <img :src="reply.user.headIco" style="width: 36px; height: 36px; background-color: #6d737b; border-radius: 50%; margin-right: 12px; margin-top: 4px">
                        <div>
                            <div style=" margin-top: 2px; margin-right: 15px;">
                                <div style="font-weight: 600; color: #444444;float: left">{{reply.user.nickName}}</div>
                                <div style="float: left;margin-left: 10px;">
                                    <div v-if="reply.user.v == 1" style="width: 20px;height: 20px;float: left">
                                        <img  src="../../../assets/imgs/index/vipBig.png" style="width: 20px; height: 20px;"/>
                                    </div>
                                    <!-- 专业  -->
                                    <div v-if="reply.user.authenticationStatus >= 2" style="width: 49px; height: 23px;float: left;margin-left:5px;">
                                        <img  src="../../../assets/imgs/index/major2.png" style="width: 49px; height: 21px;"/>
                                    </div>
                                    <!-- 专家 -->
                                    <div v-if="reply.user.authenticationStatus >= 3" style="width:49px; height: 23px;float: left;margin-left:5px;">
                                        <img  src="../../../assets/imgs/index/expertspn2.png" style="width:49px; height: 21px;"/>
                                    </div>
                                </div>
                                <div style="clear: both">
                                    <span v-if="item.user.address.cityName" style="font-size: 11.5px;color: #999999">{{item.user.address.cityName[1]}}</span>
                                    <el-divider direction="vertical"></el-divider>
                                    <span style="font-size: 11.5px;color: #999999">{{reply.user.major}}</span>
                                </div>
                                <div style="word-break:break-all;word-wrap:break-word;font-size: 13.5px;margin-top: 2px;">{{reply.content}}</div>
                                <div style="color: #999999; font-size: 8px; margin-top: 8px">{{reply.creatTime}}</div>
                            </div>
                        </div>
                    </div>
                    <!--    <div>-->
                    <!--        <div class="hand op" style="color: #1C7EFF;margin-right:5px;width: 33px" @click="showEditor(item)">回复</div>-->
                    <!--    </div>-->
                    <div style="float: right;margin-top: 75px;">
                        <!--                            <div style="float: left;margin-top: 5px">-->
                        <!--                                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon2.png" style="width: 16px;height: 17px;float: left">-->
                        <!--                                <div style="height: auto;font-size: 11px;float: left;margin: 2px;color: #999999">0</div>-->
                        <!--                            </div>-->
                        <div v-if="item.user.uuid === user.uuid" @click="delComment(reply.id)" style="color: #999999;font-size: 10px; line-height: 2px;margin-top: 8px;float: left;margin-left: 20px;cursor: pointer" >删除</div>
                    </div>
                </div>
                <div style="width: 100%;border-top: 1px solid #BDBFC1;margin-bottom: 20px"></div>

                <div v-if="item.sub.length % 6 === 0 && item.sub.length !== 0" class="hand" style="color: #1C7EFF; margin-left: 40px; margin-bottom: 10px" @click="getReply(index, item.id)">查看更多回复 <i class="el-icon-arrow-down"></i></div>
            </div>
            <div v-if="commentData.length % 6 === 0 && commentData.length !== 0" class="hand" style="color: #1C7EFF; margin-left: 42%" @click="getMoreComment">查看更多评论 <i class="el-icon-arrow-down"></i></div>
        </div>
        <el-dialog v-if="is_login === 1" :close-on-click-modal="false" :visible.sync="showLabel">
            <div style="padding: 20px; display: flex">
                <span style="font-size: 18px; font-weight: 600">标签名： </span>
                <el-input style="width: 400px" v-model="labelName"></el-input>
            </div>
            <div style="display: flex; justify-content: end">
                <el-button class="cel-btn" @click="cancelLabel">取消</el-button>
                <el-button class="save-btn" @click="saveLabel">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: Object,
            default() {
                return {}
            }
        },
        bgbTypeinfo: {
            type: Number,
            default() {
                return 7
            }
        }
    },
    data() {
        return {
            labelName: '',
            showLabel: false,
            showReply: false,
            sendContent: '',
            replyContent  : '',
            replyInfo: {
                title: '',
                cId: ''
            },
            page: 1,
            more: 1,
            pageSize: 6,
            commentData: [],
            replyData: [],
            showReplyArray:[],
        }
    },

    computed: {
        user() {
            return this.$store.state.user;
        },
        is_login(){
            return this.$store.state.is_login
        },
        router(){
            return this.$store.state.router
        },

    },

    mounted() {
        if(this.is_login === 1){
            this.getComment();
        }
    },

    methods: {
        // 获取回复
        getReply(index, id) {
            this.commentData[index].page ++;
            this.newApi.getCommentSub({
                id: id,
                page: this.commentData[index].page,
                pageSize: 10
            }).then(res => {
                for (const item of res.data) {
                    this.commentData[index].sub.push(item);
                }

                console.log(this.commentData[index].sub)
            })
        },

        // 二级回复
        addReply() {
            this.newApi.addComment({
                type: 1,
                cId: this.replyInfo.cId,
                content: this.replyContent
            }).then(res =>{
                if (res.isSuccess == 1) {
                    this.replyContent = '';
                    this.showReply = false;
                    this.getComment();
                    this.utils.sus(res.data)
                }
            })
        },

        // 添加评论
        addCommand() {
            this.newApi.addComment({
                type: 0,
                bgbType: this.content.bgbType,
                cId: this.content.id,
                content: this.sendContent
            }).then(res =>{
                if (res.isSuccess == 1) {
                    this.sendContent = '';
                    this.getComment();
                    this.utils.sus(res.data)
                }
            })
        },

        // 展示回复
        showEditor(item,index) {
            this.showReplyArray = Array(this.commentData.length).fill(false);
            this.showReplyArray[index] = true;
            this.replyInfo.title = '回复' + item.user.nickName;
            this.replyInfo.cId = item.id
        },
        cancelReply(index) {
            this.showReplyArray = Array(this.commentData.length).fill(false);
            this.showReplyArray[index] = false;
        },

        // 展示评论内容
        // showCommentInfo() {},

        // 页面初始化展示评论
        getComment() {
            this.page = 1;
            this.newApi.getComment({
                id: this.$route.params.id,
                bgbType: this.bgbTypeinfo,
                page: this.page,
                pageSize: this.pageSize
            }).then(res => {
                for (const item of res.data) {
                    item['page'] = 1
                }
                this.commentData = res.data;
            })
        },
        // 查看更多评论
        getMoreComment() {
            this.page ++;
            this.newApi.getComment({
                id: this.content.id,
                bgbType: this.content.bgbType,
                page: this.page,
                pageSize: this.pageSize}).then(res => {
                for (const item of res.data) {
                    item['page'] = 1;
                    this.commentData.push(item);
                }
            })
        },

        // 未开启评论提醒
        showCommentMessage() {
            this.utils.msg('作者未开启评论！')
        },
        // 保存标签
        saveLabel() {
            this.newApi.addCollection({
                bgbType: this.data.bgbType,
                label: this.labelName,
                id: this.data.id
            }).then(res =>{
                if (res.isSuccess == 1) {
                    this.labelName = '';
                    this.showLabel = false;
                    this.data.isCollection = 1;
                    this.utils.sus(res.data)
                }
            })
        },

        // 取消标签
        cancelLabel() {
            this.labelName = '';
            this.showLabel = false;
        },
        //  删除评论
        delComment(id){
            var that = this;
            that.newApi.delComment({
                id:id
            }).then(res =>{
                if (res.isSuccess == 1) {;
                    that.utils.sus(res.data);
                    that.getComment()
                }
            })
        },
    },
}
</script>

<style scoped>
/deep/ .el-button--primary {
    border-color: #031F88;
    background-color: #031F88;
}


.bottom{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height:50px;
    height: 50px;
    color: #444444;
    font-size: 16px;
    text-align: center;
}

.bottom .btn{
    display: flex;
    justify-content:center;
    align-items: center;
    flex: 1;
}

.dynamic-operation .save-btn {
    width: 50px;
    height: 32px;
    color: #fff;
    margin-top: 20px;
    padding: 10px;
    background-color: #1C7EFF;
    border-radius: 5px;
}

.dynamic-operation .cel-btn {
    width: 50px;
    height: 32px;
    color: #fff;
    margin-top: 20px;
    padding: 10px;
    background-color: #6d737b;
    border-radius: 5px;
}

.comment-btn {
    width: 33px;
    height: 12px;
    line-height: 12px;
    padding: 8px 18px;
    border-radius: 20px;
    background: blue;
    color: #FFFFFF;
}

/*公用及el*/
.hand{
    cursor:pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.op:active{
    opacity:0.6;
    -webkit-transition:all .1s;
    transition:all .1s;opacity:.6;
}

.op{
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .5s;
    transition: all .5s;
}
.input_dh {
    width: 311px;
    height: 108px;
    background: #F0F0F5;
    border-radius: 5px 5px 5px 5px;
    margin-left: 17px;
}

</style>
