<template>
	<div id="rightPanel">
		<div id="user" class="op sc hand" @click="goToUserInfo">
			<img id="headIcoB" :src="userId.headIco" />
			<div id="userRight">
				<div id="nickName">{{userId.nickName}}</div>
				<div id="iconList">
					<img v-if="userId.v == 1"  src="../../assets/imgs/index/vipBig.png" >
                    <img v-if="userId.authenticationStatus == 2 " src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertSpace/major.png">
					<img  v-if="userId.expertAuthStatus == 2" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/expertLink/expertSpace/inoc.png">
				</div>
			</div>
		</div>

<!--		<div id="numberList">-->
<!--			<div class="numberAndText op sc hand" @click="goToUserInfo">-->
<!--				<div class="number">{{ user.fansNum }}</div>-->
<!--				<div class="text">粉丝</div>-->
<!--			</div>-->
<!--			<div class="numberAndText op sc hand" @click="goToUserInfo">-->
<!--				<div class="number">{{  user.contentCount }}</div>-->
<!--				<div  class="text">{{typeList[type]}}</div>-->
<!--			</div>-->
<!--			<div class="numberAndText op sc hand" @click="goToUserInfo">-->
<!--				<div class="number">{{  user.clickCount }}</div>-->
<!--				<div class="text">阅读量</div>-->
<!--			</div>-->
<!--		</div>-->

		<div id="title">最近{{ typeList[type] }}</div>
		<div id="list">
			<div class="item op sc hand" v-for="item in contentList" @click="goToInfo(item)">
                <img v-if="item.cover " :src="item.cover">
                <p>{{ item.createTime }}</p>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "rightPanel",
    components: {

    },
	props:{
		userId:{
			type:Object,
			default(){
				return ""
			}
		},
		type:{
			//解释:"下方显示最近的啥？1标准规范 2政策法规 3项目案例 4文章专著 5其他 6视频 7图片 8附件",
			type:Number,
			default() {
				return 7;
			}
		},
        contentList:{
            type:Array,
            default(){
                return
            }
        }
	},
	computed: {
        user() {
			return this.$store.state.user
		}
	},
	mounted() {
	},
	watch:{

	},
	methods:{
		goToInfo(item){
			if (item.type === 7 || item.type === 6 ){
				//图片
				window.location.href = '/content/picture/'+item.id
			}
		},
		goToUserInfo(){
			if(this.user.userType == 1){
				window.location.href = '/circle/mySpace/'+this.user.uuid
			}else if(this.user.userType == 2){
				window.location.href = '/circle/organSpace/'+this.user.uuid
			}
		},
	},
	data(){
		return {
			typeList:["","标准规范","政策法规","项目案例","文章专著","其他","附件","图片","视频"],
            uuid: '',
		}
	}
}
</script>

<style scoped lang="scss">
	#rightPanel{
		border: 1px solid #DCDCDC;
		width: calc( 100% - 63px ) ;  padding: 26px 30px;
		background: #FFF;
		border-radius: 5px;

		#user{
			overflow:auto; border-bottom: 1px solid #dcdcdc;
			width: 100%; padding-bottom: 20px;
			#headIcoB{
				width: 56px; height: 56px;
				border-radius: 50%; float: left;
			}
			#userRight{
				float: left; margin-left: 14px; width: calc( 100% - 72px  );
				#nickName{
					height: 28px; line-height: 28px;
					overflow: hidden; font-size:18px ; color: #444;
					text-overflow: ellipsis; white-space: nowrap;
				}

				#iconList{
					height: 20px; padding: 4px 0;
					img{
						height: 20px; margin-right: 12px;
					}
					img.disabled{
						filter: grayscale(100%);
					}
				}
			}
		}

		#numberList{
			height: 88px; border-bottom: 1px solid #dcdcdc;
			display: flex; text-align: center;
			.numberAndText{
				flex: 1; display: flex; flex-direction: column;
				justify-content: center; gap: 8px;
				.number{
					font-size: 18px;
					font-family: FZLanTingHeiS-DB-GB-Regular, FZLanTingHeiS-DB-GB;
					font-weight: 400;
					color: #545454;
				}
				.text{
					font-size: 16px;
					font-family: FZLanTingHeiS-DB-GB-Regular, FZLanTingHeiS-DB-GB;
					font-weight: 400;
					color: #999999;
				}
			}
		}


		#title{
			margin-top: 20px;border-left: 6px solid #031F88 ; padding-left: 8px;
			height: 21px; line-height: 21px; font-size: 18px;
			font-family: FZLanTingHeiS-DB-GB-Regular, FZLanTingHeiS-DB-GB;
			font-weight: 400; color: #444444;
		}
		#list{
			margin-top: 12px;
			.item{
				margin-bottom: 20px;
				img{
					width: 240px;
					height: 140px;
					margin-bottom: 6px;
					object-fit: cover;
				}
				p{
					font-size: 14px;
					font-family: FZLanTingHeiS-M-GB-Regular, FZLanTingHeiS-M-GB;
					font-weight: 400;
					color: #999999;
				}
			}
		}


		#btnList{
			display: flex;
			.btn{
				line-height: 0;
				width: 100px;
				height: 32px;
				color: #FFF;
				background: #2970FF;
				border-radius: 16px 16px 16px 16px;
				opacity: 1;
			}

			.disabled{
				color:#999 ;
				background: #F5F5F7;
				border: 1px solid #DCDCDC;
			}
			.watch{

			}
			.msg{
				background: #2dceaa;
			}
		}


	}
</style>
