<template>

	<div id="app">
		<SubHead :title="typeList[data.bgbType]+'详情'" :title-img="data.bgbType == 7? 'https://oss.baigongbao.com/webImg/img.png':'https://baigongbao.oss-cn-beijing.aliyuncs.com/webImg/videor.png'"></SubHead>
		<div id="body">
			<div id="left">
				<Collection :content="data"></Collection>
			</div>

			<div id="center">
				<div id="title">{{data.title}}</div>
				<div id="info">
					<img id="headIco" class="op sc hand" :src="data.user.headIco" />
					<span  class="op sc hand">{{data.user.nickName}}</span>
					<span class="line">|</span>
					<span>发布于 {{data.createTime}}</span>
					<span class="line">|</span>
					<span>  <span>图片分类：</span> <span class="type op sc hand" >{{ data.typeNamelist.join(">") }}</span>   </span>
					<span class="line">|</span>
					<span>{{data.clickCount}}阅读</span>
                    <span class="right op sc hand" v-if="is_login === 1 && data.user.uuid == me.uuid">
                        <el-dropdown @command="more_action" >
                        <span class="el-dropdown-link">
                            <i class="el-icon-caret-bottom" style="font-size: 24px"></i>
                        </span>
                            <el-dropdown-menu slot="dropdown" >
                                 <el-dropdown-item class="right op sc hand" command="save" >编辑内容</el-dropdown-item>
                                <el-dropdown-item  command="delete" >删除内容</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </span>
				</div>
				<div id="content">
			    <div class="img" v-if="data.images.length > 0" v-for="(item,index) in data.images" >
                    <div v-if="is_login === 1">
                        <div v-if="data.uuid == me.uuid">
                            <img :src="item.url"  :key="'images'+index" :lazy="true" />
                            <div class="describe">{{item.describe}}</div>
                        </div>

                        <div v-if="data.uuid != me.uuid">
                            <div v-if="(data.payAmt > 0 && data.PayStatus == 1) || (data.payAmt == 0 && data.PayStatus == 0)">
                                <img :src="item.url"  :key="'images'+index" :lazy="true" />
                                <div class="describe">{{item.describe}}</div>
                            </div>

                            <div v-if="data.payAmt > 0 && data.PayStatus == 0">
                                <div v-if="index == 0" style="overflow: hidden;position: relative;">
                                    <img :src="item.url" :style="data.images.length == 1?'filter: blur(8px)':''" :key="'images'+index" :lazy="true"  />
                                    <div class="describe">{{item.describe}}</div>
                                </div>
                                <div v-if="index > 0"  style="overflow: hidden;position: relative;">
                                    <img :src="item.url" style="filter: blur(8px);" :key="'images'+index" :lazy="true" />
                                    <div class="describe">{{item.describe}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="is_login === 0">
                        <img :src="item.url"  :key="'images'+index" :lazy="true" />
                        <div class="describe">{{item.describe}}</div>

                        <div v-if="(data.payAmt > 0 && data.PayStatus == 1) || (data.payAmt == 0 && data.PayStatus == 0)">
                            <img :src="item.url"  :key="'images'+index" :lazy="true" />
                            <div class="describe">{{item.describe}}</div>
                        </div>

                        <div v-if="data.payAmt > 0 && data.PayStatus == 0">
                            <div v-if="index == 0" style="overflow: hidden;position: relative;">
                                <img :src="item.url" :style="data.images.length == 1?'filter: blur(8px)':''" :key="'images'+index" :lazy="true"  />
                                <div class="describe">{{item.describe}}</div>
                            </div>
                            <div v-if="index > 0"  style="overflow: hidden;position: relative;">
                                <img :src="item.url" style="filter: blur(8px);" :key="'images'+index" :lazy="true" />
                                <div class="describe">{{item.describe}}</div>
                            </div>
                        </div>
                    </div>
				</div>

                      <div v-if="(data.uuid == me.uuid)">
                        <video-player v-if="data.videos != null && data.videos.length>0" class="video-player vjs-custom-skin" id="videoPlayer" ref="videoPlayer" :playsinline="true" :options.sync="playerOptions"></video-player>
                      </div>

                      <div v-if="(data.uuid != me.uuid) && ((data.payAmt > 0 && data.PayStatus == 1) || (data.payAmt == 0 && data.PayStatus == 0))">
                        <video-player v-if="data.videos != null && data.videos.length>0" class="video-player vjs-custom-skin" id="videoPlayer" ref="videoPlayer" :playsinline="true" :options.sync="playerOptions"></video-player>
                      </div>
                      <div v-if="is_login === 1 && (data.uuid != me.uuid) && (data.payAmt > 0 && data.PayStatus == 0)">
                        <div style="width: 100%;display: flex;justify-content: center">
                          <div style="width: 16px;height: 18px;margin: 5px 5px 3px 0">
                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon6.png" style="width: 16px;height: 18px">
                          </div>
                          <div style="height: 21px;font-size: 17px;margin-top: 2px">{{detilName}}</div>
                        </div>
                        <div  style="margin-top: 10px;overflow: hidden;display: flex;justify-content: center;width: 100%;">
                          <el-button style="overflow: hidden;" @click="payKnowledge"  type="info" plain>立即购买：￥{{data.payAmt}}</el-button>
                        </div>
                      </div>
                    <div v-if="is_login === 0 && data.payAmt > 0 && data.PayStatus == 0">
                        <div style="width: 100%;display: flex;justify-content: center">
                            <div style="width: 16px;height: 18px;margin: 5px 5px 3px 0">
                                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon6.png" style="width: 16px;height: 18px">
                            </div>
                            <div style="height: 21px;font-size: 17px;margin-top: 2px">{{detilName}}</div>
                        </div>
                        <div  style="margin-top: 10px;overflow: hidden;display: flex;justify-content: center;width: 100%;">
                            <el-button style="overflow: hidden;" @click="payKnowledge"  type="info" plain>立即购买：￥{{data.payAmt}}</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <!--   推荐信息   -->
			<div id="right"><RightPanel :userId="data.user" :type="data.bgbType" :contentList="contentList"></RightPanel></div>
            <el-dialog :close-on-click-modal="false" width="25.8%" :visible.sync="uploadvideo" :show-close=false class="upload_att">
                <addUploadVideo :info = data :on-close="closeEdit" :editId="data.id" @changeResh="gekwInfo"></addUploadVideo>
            </el-dialog>
      </div>

      <add-image-file-base-form :data = data :is-show="editOpen" :on-close="closeEdit" :edit-id="data.id" @changeResh="gekwInfo"></add-image-file-base-form>
        <!--   评论组件 -->
      <div v-if="is_login === 1" style="display: flex;justify-content: center;margin-right: 250px;">
          <div id="target" style="width: 1110px;height: 100%;margin-bottom: 20px">
              <multimedia-reply :content="data" :bgbTypeinfo="data.bgbType"></multimedia-reply>
          </div>
      </div>
    <div v-if="is_login === 0" style="display: flex;justify-content: center;margin-right: 250px;">
        <div style="margin: 0px auto">
            <div style="width:1110px;border-top: 1px solid #BDBFC1;margin-top: 40px;"></div>
            <span style="font-size: 14px;">评论区</span>
            <div style="overflow: hidden;background: #FFFFFF;white-space: pre-wrap;width: calc(100% - 30px;);border-radius: 5px;padding: 50px 0px 50px 0px;">
                <div style="display: flex;justify-content: center">
                    <div class="op sc hand"><el-button type="primary"  style="font-size: 15px;" @click="logintoview">登录查看评论</el-button></div>
                </div>
            </div>
        </div>
    </div>
	</div>
</template>

<script>
import SubHead from "@/components/common/SubHead.vue";
import RightPanel from "@/components/common/rightPanel.vue";
import Collection from "./components/Collection.vue"
import AddImageFileBaseForm from "../../components/common/addImageFileBaseForm.vue";
import addUploadVideo from "../../components/common/addUploadVideo.vue";
import MultimediaReply from "./components/multimediaReply";


export default {
	name:"Dynamic",
	components: {
      MultimediaReply,
      AddImageFileBaseForm,
      Collection,
      RightPanel,
      SubHead,
      addUploadVideo,
	},
	computed: {
		me() {
			return this.$store.state.user
		},
		is_login(){
			return this.$store.state.is_login
		},
        intermediateValue: {
            get() {
                return this.parentValue;
            },
            set(value) {
                // 发送值到父组件
                this.$emit('update:parentValue', value);
            },
        },
	},
	data () {
		return {
            detilName:"有偿阅读",
			typeList:["","标准规范","政策法规","项目案例","文章专著","其他","视频","图片","视频"],
			//是否展示
			show:true,
            editOpen:false,
            uploadvideo:false,
			data: {
				typeNamelist:[],
				user:{
					headIco:"",
				},
			},
			playerOptions: {},
            sign:'',
            contentList:[]

		}
	},
	props:{
		//是否展示来自那个room
		showRoom:{
			type:Boolean,
			default(){
				return true
			}
		}
	},
	watch:{
	
	},
    created() {
        var that = this;
        //预支付订单监听
        this.$EventBus.$EventBus.$off("prePayOrder");
        this.$EventBus.$on("prePayOrder", (data) => {
            this.$nextTick(() => {
                if (data.code == 100) {
                    that.bankPay(data.toPay);
                }
            });
        });
    },
  mounted:function(){
    var that = this;
    this.id = this.$route.params.id;
    this.gekwInfo();

    //监听支付成功
    this.$EventBus.$on("payMsg", (data) => {
      this.$nextTick(() => {
        if (data.code == 100) {
             location.reload();
        }
      });
    });

  },
	methods:{
    //调起支付
    payKnowledge(){
      var that = this;
      if(that.is_login === 0){
          this.$store.commit('setShowLoginPopup', true);
          return
      }
      that.newApi.payKnowledge({
        knowledgeId:that.id,
        paidUuid:that.me.uuid,
      }).then((res)=>{
        if (res.isSuccess == 1) {
          that.orderNo = res.data.orderNo;
          //<AaDaPayCode ref="aDaPayCode" type="2" payType="14"  :memberId="paidKnowledgeinfo.memberId"  :payAmt="paidKnowledgeinfo.payAmt" :collectionUuid="paidKnowledgeinfo.addUuid" :orderNo="orderNo" @change="changeOrder($event)"></AaDaPayCode>
            let params = that.$store.state.payParams;
            params.type = 2;
            params.payAmt = res.data.payAmt;
            params.payType = 14;
            params.memberId = res.data.memberId;
            params.collectionUuid = res.data.addUuid;
            params.orderNo = res.data.orderNo;
            that.sign = that.utils.random_string(6);
            that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                that.b('/newAdaPay/?sign='+that.sign);
            })
        }
      }).catch((err)=>{
        console.log(err)
      })
    },

    //立即支付
    bankPay(data){
        var that = this;
        //判断是对公还是公绑私
        if(data.activeName != 'corporate'){
            var params = {};
            params.orderNo = data.orderNo;
            params.code = data.code;
            params.payPwd = data.payPwd;
            that.newApi.settleAccountTransferDetermine(params).then((ret) => {
                if(ret.isSuccess == 1){
                    console.log(ret.data)
                }
            });
        }
    },

    init(){
        var that = this;
        if(that.data.videos!= null && that.data.videos.length > 0){
            that.playerOptions = {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4", // 类型
                    src: that.data.videos[0].url// url地址
                }],
                poster: that.data.videos[0].cover, // 封面地址
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            }
        }
    },

    closeEdit(){
        // this.gekwInfo();
        this.editOpen = false;
        this.uploadvideo = false;
    },

		gekwInfo: function () {
			var that = this;
			this.newApi.getKnowledgeInfo({
				  id: that.id
			}).then((res) => {
            that.data = res.data;
                this.newApi.getFilebaseList({
                    uuid: res.data.uuid,
                    page: 1,
                    pageSize:3,
                    bgbType:res.data.bgbType,
                }).then(res =>{
                    console.log("getFilebaseList",res);
                    if (res.isSuccess === 1) {
                        that.contentList = res.data;
                    }
                })
          if(that.data.msg != ""){
            that.utils.msg(that.data.msg);
          }
          document.title = that.typeList[res.data.bgbType]+'详情页';
          if (res.data.videos.length > 0){
              that.init()
          }
			}).catch((err) => {
          console.log(err)
			})
		},

            //下拉菜单触发的方法
            more_action:function(e){
                if (this.is_login == 0) {
                    this.err("请先登录");
                    return;
                }
                var that = this;
                if(that.data.payAmt > 0){
                    that.utils.msg("付费内容不允许编辑或删除");
                    return false;
                }

                  if(e == "save"){
                      if(that.data.user.uuid == that.me.uuid && that.data.images.length > 0){
                            that.editOpen = true
                      }else if(that.data.user.uuid == that.me.uuid && that.data.videos.length > 0){
                          that.uploadvideo = true
                      }
                  }

                    if (e == "delete") {
                        that.$confirm('删除后无法恢复，确认删除吗？').then(() => {
                            this.newApi.delKnowledge({
                                id:that.data.id,
                                type:0,
                            }).then(ret =>{
                                if (ret.isSuccess === 1){
                                      that.utils.sus(ret.data);
                                      that.$router.push({path:'/filebase/index'});
                                  }else {
                                      that.utils.err('删除失败');
                                      return
                                  }
                            })
                        })
                    }
                },
                // 登陆查看事件
                logintoview(){
                    this.$store.commit('setShowLoginPopup', true);
                }
            }
}
</script>

<style scoped lang="scss">
	#app{
		background: #FFF;

		#body{
			width: 1500px;
			margin:60px auto 0 auto;
			padding-top: 40px;
			overflow: hidden;
			#left {
				float: left;
				width: 50px;
				margin-top: 105px;
			}
			#center{
				float: left;
				width: 1110px;
				margin-left: 20px;
				#title{
					font-size: 30px; font-family: FZLanTingHeiS-M-GB-Regular, FZLanTingHeiS-M-GB;
					font-weight: 400; color: #333333; line-height: 40px;
				}
				#info{
					font-size: 18px;
					line-height: 26px;
					height: 26px; color: #444;
					margin-top: 30px;
					#headIco{
						width: 26px; height: 26px; border-radius: 50%;
						float: left; margin-right: 10px;
					}
					span{
						float: left;
					}
					.type{
						color: #031F88;
					}
					.line{
						margin: 0 20px; color: #BDBFC1;
					}
					.right{
						float: right; color: #999; font-size: 16px;
					}
				}

				#content{
					margin-top: 20px;
					.img{
						width: 100%;
						margin-bottom: 30px;
						img{
							width: 100%;
						}

						.describe{
							margin-top: 16px;
							font-size: 16px;
							font-family: FZLanTingHeiS-M-GB-Regular, FZLanTingHeiS-M-GB;
							font-weight: 400;
							color: #606060;
							line-height: 24px;
						}



					}
				}
			}

			#right{
				float: right; width: 300px;
			}
		}
	}



</style>
